import React, { Component } from 'react';
import { DatePicker } from 'sw-ui';

import PropTypes from 'prop-types';

import SelectProject from '../selectProject';
import SelectCompany from '../selectCompany';
import AnalyticsRows from '../analyticsRows';

import Input from '../../../../components/input';
import Radio from '../../../../components/radio';
import { FlatButton } from '../../../../components/button';
import Checkbox from '../../../../components/checkbox';
import SelectWithInput from '../../../../components/SelectWithInput';

import {
  FIELDS,
  FUNDSFIELDS,
  FUNDSLABELS,
  FUNDSFIELDSTYPES,
  TRIPSLABELS,
  INN_LENGHTS,
} from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import COMPONENTS from '../../../../bi/constants/components';
import { DATE } from '../../../../constants/time';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TYPERATRATES } from '../../../../bi/constants/account';
import { PROVIDER, ALVISTA_PROVIDERS } from '../../../../bi/constants/mice';

import lodashReplaces from '../../../../bi/utils/lodashReplaces';
import {
  applyAnalytics, removeAnalytics, validateSingleAnalytics, validateAnalytics,
} from '../../../../bi/utils/customAnalytics';
import { isMandatory } from '../../../../bi/utils/account';
import {
  validateDateAndTime,
  isIntNumberOrDash,
} from '../../../../bi/utils/trip';
import {
  getDate,
  isMoment,
  getMoment,
  momentObject,
  isBeforeDateParameter,
  isValidMomentObject,
} from '../../../../bi/utils/formatDate';
import { formatDate } from '../../../../utils/date';

import renderFundsForm from '../form';

import { UploadVoucher } from './components/UploadVoucher';

import styles from '../../styles/form.module.scss';

const LABELS = {
  DESCRIPTION: 'Наименование изменения',
  NAME_DESCRIPTION: 'Описание изменения',
  VOUCHER_INFO: 'Данные ваучера',
  NAME: 'Название услуги',
  TRAIN: 'Ж/д билет',
  CHECKIN_DATE: 'Дата начала услуги',
  CHECKIN_TIME: 'Время начала услуги',
  ORDER_ID: 'Номер бронирования у поставщика',
  PROVIDER_NAME: 'Поставщик услуги',
  CHECKOUT_DATE: 'Дата окончания услуги',
  CHECKOUT_TIME: 'Время окончания услуги',
  BASE: 'Цена поставщика',
  COMMISSION: 'Наценка Smartway',
  ADD_TRIP: 'Добавить заказ',
  PRICE: 'Цена',
  FINANCE_OPERATIONS: 'Финансовые операции',
  CONFIRM: 'Подтвердить изменения',
  ADD_NEW_VOUCHER: 'Добавить новый ваучер',
  DELETE_VOUCHER: 'Удалить ваучер',
  INN_MESSAGE: 'ИНН должен содержать 10, 12 или 13 цифр',
  SELECT_SUPPLIER: 'Выберите поставщика',
  DELETE_SUPPLIER: 'Удалить поставщика',
  ADD_SUPPLIER: 'Добавить поставщика',
  SELECT_PRICE: 'Введите цену поставщика',
  INN_ALVIST: '7703236362',
};

const LENGTH_INN = {
  TEN: 10,
  ELEVEN: 11,
};

const LABELS_VAT = {
  WITH: 'С НДС',
  WITHOUT: 'Без НДС',
};

const VAT_VALUE = {
  WITH: true,
  WITHOUT: false,
};

export default class EventsForm extends Component {
  static propTypes = {
    event: PropTypes.object,
    companies: PropTypes.array,
    tripService: PropTypes.object,
    projects: PropTypes.array,
    mandatoryProject: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    addTrip: PropTypes.func,
    guidService: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    isNew: PropTypes.bool,
    isTripPaidByPersonalFunds: PropTypes.bool,
  };

  static defaultProps = {
    tripService: [],
    event: [],
    companies: [],
    projects: [],
    mandatoryProject: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    isEditing: false,
    setTripAnalytics: () => {},
    addTrip: () => {},
    isNew: false,
    isTripPaidByPersonalFunds: false,
  };

  constructor(props) {
    super(props);
    const { event } = props;

    this.state = { ...this.setEvent(event) };
  }

  componentDidMount() {
    this.unsubscribe = this.props.tripService.subscribe(this.updateTripData);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ eventFile }) => this.setState({ eventFile });

  setEvent = (event) => {
    const { eventFile } = this.props.tripService.get();
    const {
      isEditing, mandatoryProject, tripService: { addVouchers }, isNew,
    } = this.props;
    const {
      isEdit,
      OrderTripItemId,
      ServiceType,
      JsonData,
      PriceDetails,
      UserAnalytics,
      CompanyId,
      ProjectId,
      DepartmentId,
      ProviderName,
      ProviderInn,
      Segments,
    } = event;
    const data = typeof JsonData === 'string' ? JSON.parse(JsonData) : JsonData;

    const provider = ProviderName === ALVISTA_PROVIDERS.label
      ? ALVISTA_PROVIDERS
      : {
        id: 0, type: TYPERATRATES.OPTIONAL, value: 0, label: ProviderName,
      };

    const { PriceDetails: PriceDetailsJson } = data;
    const newMandatory = isMandatory(mandatoryProject, CompanyId);

    const state = (json) => ({
      mandatoryProject: newMandatory,
      serviceTypes: [],
      ProviderInn: ProviderInn || '',
      JsonData: {
        ...json,
        CheckinDate: momentObject(json.CheckinDate),
        CheckoutDate: momentObject(json.CheckoutDate),
        Segments: json.Segments || [],
      },
    });

    if (isEdit) {
      const jsonData = {
        ...state(JsonData),
        JsonData: { ...state(JsonData).JsonData, Segments: state(JsonData).JsonData.Segments || [] },
      };

      return {
        ...event,
        ...jsonData,
        ProjectId: ProjectId || 0,
      };
    }

    if (isEditing) {
      const currentData = JSON.parse(JsonData);

      const jsonData = { ...currentData };

      const eventVoucher = {
        file: jsonData.VoucherFileName || !isNew ? new File([jsonData.VoucherFileName], jsonData.FileName) : null,
        uploaded: !!jsonData.VoucherFileName,
        guid: jsonData.VoucherFileName,
      };

      addVouchers([eventVoucher]);

      return {
        ...state(jsonData),
        Description: '',
        OrderTripItemId,
        ServiceType: 'MICE',
        Status: 1,
        CompanyId: CompanyId || 0,
        ProjectId: ProjectId || 0,
        DepartmentId: DepartmentId || 0,
        Departments: [],
        UserAnalytics: UserAnalytics || [],
        mandatoryProject: newMandatory,
        ProviderInn: ProviderInn,
        Segments: Segments || [],
        PriceDetails: {
          ...PriceDetailsJson,
          Commission: PriceDetailsJson.Commission,
          hasVat: PriceDetailsJson.HasVAT,
        },
        Surcharges: {
          Enabled: false,
          Base: 0,
          Commission: 0,
          Description: '',
        },

        validation: {
          Name: '',
          CheckinDate: '',
          CheckoutDate: '',
          CompanyId: '',
          ProjectId: '',
          DepartmentId: '',
          Description: '',
          Base: '',
          Commission: '',
          analytics: {},
          ProviderInn: '',
        },
        Providers: provider,

        eventFile: [
          {
            ...eventFile[0],
            ...eventVoucher,
          },
        ],
        isLoading: false,
      };
    }

    const files = !isNew
      ? eventFile
      : [
        {
          file: null, filed: false, uploaded: false,
        },
      ];

    return {
      ...state(JsonData),
      OrderTripItemId,
      ServiceType,
      Status: 0,
      CompanyId: 0,
      PriceDetails,
      ProjectId: 0,
      DepartmentId: 0,
      Departments: [],
      UserAnalytics: UserAnalytics || [],

      validation: {
        Name: '',
        CheckinDate: '',
        CheckoutDate: '',
        CompanyId: '',
        ProjectId: '',
        DepartmentId: '',
        Base: '',
        Commission: '',
        analytics: {},
        ProviderInn: '',
      },

      eventFile: files,
      isLoading: false,
    };
  };

  normalizeDate = () => {
    const { JsonData } = this.state;

    JsonData.CheckinDate = formatDate(JsonData.CheckinDate, DATE) || null;
    JsonData.CheckoutDate = formatDate(JsonData.CheckoutDate, DATE) || null;
  };

  handleChangeInput = (_e, field, value) => {
    const part = field.split('.');

    if (part.length === 1) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          [field]: value,
        },
      });
    }

    return this.setState({
      JsonData: {
        ...this.state.JsonData,
        [part[0]]: {
          ...this.state.JsonData[part[0]],
          [part[1]]: value,
        },
      },
    });
  };

  handleChangePriceInput = (field, value, index = null) => {
    if (index === null) {
      return this.setState({
        PriceDetails: {
          ...this.state.PriceDetails,
          [field]: value,
        },
      });
    }

    this.setState((prevState) => {
      const updatedSegments = [...(prevState.JsonData.Segments || [])];
      updatedSegments[index] = {
        ...updatedSegments[index],
        [field]: value,
      };

      const baseTotal = updatedSegments.reduce(
        (sum, seg) => sum + (parseFloat(seg.Base) || 0),
        0,
      );
      const CommissionTotal = updatedSegments.reduce(
        (sum, seg) => sum + (parseFloat(seg.Commission) || 0),
        0,
      );

      return {
        JsonData: {
          ...prevState.JsonData,
          Segments: updatedSegments,
          PriceDetails: {
            ...prevState.JsonData.PriceDetails,
            Base: baseTotal,
            Commission: CommissionTotal,
          },
        },
        PriceDetails: {
          ...prevState.PriceDetails,
          Base: baseTotal,
          Commission: CommissionTotal,
        },
      };
    });
  };

  handleChangeDate = async (field, { value }) => {
    await this.setState({
      JsonData: {
        ...this.state.JsonData,
        [field]: value,
      },
    });

    this.validationDate(field, value);
  };

  handleSendVouchers = async () => {
    const { eventFile } = this.state;
    const { tripService: { uploadEventFiles } } = this.props;

    const files = eventFile.filter(({ file }) => !!file).map(({ file }) => file);

    if (eventFile[0].guid) return eventFile[0].guid;

    if (!files.length) return '';

    const fileId = await uploadEventFiles(files);

    return fileId;
  };

  handleConfirm = async () => {
    const {
      OrderTripItemId,
      CompanyId,
      ServiceType,
      Status,
      PriceDetails,
      JsonData,
      Description,
      Surcharges,
      ProjectId,
      DepartmentId,
      UserAnalytics = [],
      eventFile,
      ProviderInn,
    } = this.state;
    const {
      addTrip, guidService, onConfirm, event, isEditing, tripService: { resetEventFile },
    } = this.props;
    const Segments = JsonData?.Segments || [];

    const isInnInvalid = (inn) => inn.length < LENGTH_INN.TEN || inn.length === LENGTH_INN.ELEVEN;

    const hasInvalidInn = this.state.JsonData?.Segments?.some((segment) => {
      return isInnInvalid(segment.ProviderInn || '');
    });

    if (hasInvalidInn) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          Segments: prevState.JsonData?.Segments?.map((segment) => ({
            ...segment.validation,
            ProviderInn: isInnInvalid(segment.ProviderInn || '') ? LABELS.INN_MESSAGE : null,
          })) || [],
        },
      }));

      return;
    }

    if (!this.validateSegments()) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          Segments: prevState.JsonData?.Segments?.map((segment) => ({
            ProviderInn: segment.ProviderInn ? null : LABELS.INN_MESSAGE,
            ProviderName: segment.ProviderName?.label ? null : LABELS.SELECT_SUPPLIER,
            Base: segment.Base ? null : LABELS.SELECT_PRICE,
          })) || [],
        },
      }));

      return;
    }

    const { isValid, validation } = this.validationSubmit({
      JsonData,
      PriceDetails,
      CompanyId,
      ProjectId,
      DepartmentId,
      ProviderInn,
    });

    if (isValid) {
      this.setState({ isLoading: true });
      const Guid = guidService.generate();
      const stateToSave = JSON.parse(JSON.stringify(this.state));
      stateToSave.Guid = Guid;
      stateToSave.eventFile = eventFile;

      JsonData.VoucherFileName = await this.handleSendVouchers();

      this.normalizeDate();

      const preparedSegments = Segments.map(({
        ProviderName, ProviderInn, OrderId, Base, Commission,
      }) => ({
        ProviderName: ProviderName?.label || ProviderName,
        ProviderInn,
        OrderId,
        Base,
        Commission,
      }));

      const updatedPriceDetails = Segments.length > 0
        ? {
          ...PriceDetails,
          Base: preparedSegments.reduce((sum, seg) => sum + Number(seg.Base || 0), 0),
          Commission: preparedSegments.reduce((sum, seg) => sum + Number(seg.Commission || 0), 0),
        }
        : PriceDetails;

      const updatedJsonData = {
        ...JsonData,
        PriceDetails: updatedPriceDetails,
        Segments: preparedSegments,
      };

      const item = {
        OrderTripItemId,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),

        Status,
        ServiceType,
        ProviderInn,

        UserAnalytics,
        Guid,
        indexEdited: event.indexEdited,

        JsonData: JSON.stringify(updatedJsonData),
      };

      if (isEditing) {
        item.Description = Description;
        item.Surcharges = Surcharges.Enabled ? [lodashReplaces.omit(Surcharges, FIELDS.ENABLED)] : [];
        item.PriceDetails = updatedPriceDetails;
      } else {
        item.PriceDetails = updatedPriceDetails;
      }

      this.setState(() => {
        return { JsonData: updatedJsonData };
      }, () => {
        resetEventFile();
        addTrip(stateToSave);
        onConfirm({ ...item, JsonData: JSON.stringify(this.state.JsonData) });
      });
    } else {
      this.setState(() => ({ validation: { ...validation } }));
    }
    this.setState({ isLoading: false });
  };

  uploadFile = (file, ind) => {
    const { tripService: { uploadEventFile } } = this.props;

    uploadEventFile(file, ind);
  };

  handleAddNewVoucher = () => {
    const { tripService: { addEventVoucher } } = this.props;

    addEventVoucher();
  };

  handleSelectCompany = ({ id }) => {
    const { tripService: { eventTripFieldValidation }, mandatoryProject } = this.props;

    const newMandatory = isMandatory(mandatoryProject, id);
    const projectResult = newMandatory ? eventTripFieldValidation(FIELDS.PROJECTID, null) : '';

    this.setState({
      CompanyId: id,
      mandatoryProject: newMandatory,
      validation: {
        ...this.state.validation,
        ProjectId: projectResult,
        CompanyId: eventTripFieldValidation(FIELDS.COMPANYID, id),
      },
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService } = this.props;
    const { mandatoryProject } = this.state;
    const result = mandatoryProject ? tripService.eventTripFieldValidation(FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip ? {} : { UserAnalytics: updatedAnalyticsValues };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handleSelectDepartment = ({ id }) => {
    const { tripService } = this.props;
    const { Departments } = this.state;

    const result = Departments.length ? tripService.eventTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';

    this.setState({
      DepartmentId: id,
      validation: {
        ...this.state.validation,
        DepartmentId: result,
      },
    });
  };

  handleChangeVat = (field, value) => {
    this.setState((prevState) => {
      const newVatValue = value === 'true';

      return {
        PriceDetails: {
          ...prevState.PriceDetails,
          hasVat: newVatValue,
        },
        JsonData: {
          ...prevState.JsonData,
          Segments: prevState.JsonData.Segments.map((segment, index) => ({
            ...segment,
            hasVat: index === 0 ? newVatValue : prevState.JsonData.Segments[0].hasVat,
          })),
        },
      };
    });
  };

  validationSubmit = ({
    JsonData, PriceDetails, CompanyId, ProjectId, DepartmentId,
  }) => {
    const {
      tripService,
      analytics,
      isEditing,
      projects,
      isTripPaidByPersonalFunds,
      tripAnalyticsValueIds: tripAnalytics,
    } = this.props;
    const {
      Departments,
      mandatoryProject,
      Description,
      ProviderInn,
      UserAnalytics: serviceAnalytics,
    } = this.state;

    const projectMassage = projects.length
      ? tripService.eventTripFieldValidation(FIELDS.PROJECTID, ProjectId)
      : ERRORSFORALL.NO_PROJECTS;

    const name = tripService.eventTripFieldValidation(FIELDS.NAME, JsonData.Name);
    const checkinDate = tripService.eventTripFieldValidation(FIELDS.CHECKIN_DATE, JsonData.CheckinDate);
    const companyId = tripService.eventTripFieldValidation(FIELDS.COMPANYID, CompanyId);
    const projectId = mandatoryProject && !isTripPaidByPersonalFunds ? projectMassage : '';
    const departmentId = Departments.length > 0
      ? tripService.eventTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId)
      : '';
    const price = tripService.eventTripFieldValidation(FUNDSFIELDS.BASE, PriceDetails.Base);
    const userAnalytics = validateAnalytics(tripAnalytics, serviceAnalytics, analytics);
    const hasUnsetRequiredUserAnalytics =
      !isTripPaidByPersonalFunds && Object.keys(userAnalytics).some((key) => !!userAnalytics[key]);
    const description = isEditing
      ? tripService.eventTripFieldValidation(FIELDS.DESCRIPTION, Description)
      : '';
    const providerName = tripService.eventTripFieldValidation(FIELDS.PROVIDERNAME, JsonData.ProviderName);
    const inn = tripService.eventTripFieldValidation(FIELDS.PROVIDER_INN, ProviderInn);

    let checkoutDate;

    if (isMoment(JsonData.CheckinDate)
      && JsonData.CheckinDate.isValid()
      && isMoment(JsonData.CheckoutDate)
      && JsonData.CheckoutDate.isValid()) {
      checkoutDate = tripService.eventTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else {
      checkoutDate = tripService.eventTripFieldValidation(FIELDS.CHECKOUT_DATE, JsonData.CheckoutDate);
    }

    if (
      name
      || checkinDate
      || checkoutDate
      || companyId
      || projectId
      || departmentId
      || price
      || hasUnsetRequiredUserAnalytics
      || description
      || providerName
      || inn
    ) {
      return {
        isValid: false,
        validation: {
          Name: name,
          CheckinDate: checkinDate,
          CheckoutDate: checkoutDate,
          Base: price,
          CompanyId: companyId,
          ProjectId: projectId,
          DepartmentId: departmentId,
          MandatoryProject: mandatoryProject,
          analytics: userAnalytics,
          Description: description,
          ProviderName: providerName,
          ProviderInn: inn,
        },
      };
    }

    return { isValid: true };
  };

  handleSelectProvider = ({ label, value }) => {
    const { tripService, isEditing } = this.props;

    if (isEditing && !label) {
      return;
    }

    const result = tripService.customTripFieldValidation(FIELDS.PROVIDERNAME, label);
    const isAlvist = value === ALVISTA_PROVIDERS.value;
    const innValue = isAlvist ? LABELS.INN_ALVIST : '';

    this.setState((prevState) => ({
      Providers: { label, value },
      ProviderInn: innValue,
      JsonData: {
        ...prevState.JsonData,
        ProviderName: label,
        ProviderInn: innValue,
      },
      validation: {
        ...prevState.validation,
        Providers: result,
      },
    }));
  };

  validationInput = (field) => {
    const result = this.props.tripService.eventTripFieldValidation(field, this.state.JsonData[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationHistory = (field) => {
    const result = this.props.tripService.eventTripFieldValidation(field, this.state[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationDate = (field, value) => {
    const { JsonData } = this.state;
    const { tripService: { eventTripFieldValidation }, isEditing } = this.props;

    const {
      result,
      resultCheckin,
      resultCheckout,
      resultCheckoutLess,
    } = validateDateAndTime(field, value, isEditing, JsonData, eventTripFieldValidation);

    if (resultCheckin || resultCheckout || resultCheckoutLess) {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result,
          CheckinDate: resultCheckin,
          CheckoutDate: resultCheckout || resultCheckoutLess,
        },
      });
    } else {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result || '',
          CheckinDate: '',
          CheckoutDate: '',
        },
      });
    }
  };

  validationPrice = (field) => {
    const result = this.props.tripService.eventTripFieldValidation(field, this.state.PriceDetails[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validateProviderInn = (e, field, index) => {
    const {
      ProviderInn, validation, JsonData: { Segments },
    } = this.state;

    const { eventTripFieldValidation } = this.props.tripService;

    if (typeof index !== 'undefined') {
      const innValue = Segments[index]?.ProviderInn || '';
      const result = this.props.tripService.eventTripFieldValidation(field, innValue);

      const updatedSegments = [...Segments];
      updatedSegments[index] = {
        ...updatedSegments[index],
        validation: {
          ...updatedSegments[index].validation,
          ProviderInn: !!result ? null : result,
        },
      };

      this.setState({
        JsonData: {
          ...this.state.JsonData,
          Segments: updatedSegments,
        },
      });
    } else {
      const result = eventTripFieldValidation(field, ProviderInn || '');

      this.setState({
        validation: {
          ...validation,
          ProviderInn: !!result ? null : result,
        },
      });
    }
  };

  validateSegmentsInn = (e, field, index) => {
    const { JsonData } = this.state;
    const { Segments = [] } = JsonData;

    if (typeof index !== 'undefined' && Segments[index]) {
      const innValue = Segments[index]?.ProviderInn || '';
      const result = this.props.tripService.eventTripFieldValidation(field, innValue);

      const updatedSegments = [...Segments];
      updatedSegments[index] = {
        ...updatedSegments[index],
        validation: {
          ...updatedSegments[index].validation,
          ProviderInn: !!result ? null : result,
        },
      };

      this.setState({
        JsonData: {
          ...this.state.JsonData,
          Segments: updatedSegments,
        },
      });
    }
  };

  validateSegments = () => {
    const { JsonData: { Segments = [] } } = this.state;

    return Segments.length === 0 || Segments.every(({
      ProviderInn, ProviderName, Base,
    }) => ProviderInn && ProviderName && Base);
  };

  handleChangeFinancesEnabled = (e, field, value) => {
    const part = field.split('.');

    return this.setState({
      [part[0]]: {
        ...this.state[part[0]],
        [part[1]]: value,
      },
    });
  };

  handleChangeDescription = (e, field, value) => this.setState({ Description: value });

  handleChangeSurcharges = (e, field, value) => this.setState({
    Surcharges: {
      ...this.state.Surcharges,
      [field]: value,
    },
  });

  handleChangeNumberInput = (field, value) => {
    const { validation } = this.state;

    if ((value === '' || isIntNumberOrDash(value)) && value.length <= INN_LENGHTS.MAX) {
      const result = this.props.tripService.eventTripFieldValidation(field, value);

      this.setState({
        ProviderInn: value,
        validation: {
          ...validation,
          ProviderInn: !!result ? null : result,
        },
      });
    }
  };

  handleAddProvider = () => {
    this.setState((prevState) => {
      const { JsonData, PriceDetails } = prevState;
      const { Segments = [] } = JsonData;

      const firstVatValue = Segments.length === 0 ? VAT_VALUE.WITHOUT : Segments[0].hasVat;

      const newSegment = {
        ProviderName: '',
        ProviderInn: '',
        OrderId: '',
        hasVat: firstVatValue,
      };

      return {
        JsonData: {
          ...JsonData,
          Segments: [...Segments, newSegment],
        },
        PriceDetails: {
          ...PriceDetails,
          hasVat: Segments.length === 0 ? VAT_VALUE.WITHOUT : PriceDetails.hasVat,
        },
        providersVisible: true,
      };
    }, this.validateSegments);
  };

  handleRemoveProvider = (indexToRemove) => {
    this.setState((prevState) => {
      const updatedSegments = prevState.JsonData.Segments.filter((el, index) => index !== indexToRemove);

      return {
        JsonData: {
          ...prevState.JsonData,
          Segments: updatedSegments,
        },
        providersVisible: updatedSegments.length > 0,
      };
    },
    () => this.validateSegments());
  };

  handleChangeProviderField = (value, index, field) => {
    const isValidINN = (inn) => {
      return inn === '' || (isIntNumberOrDash(inn) && inn.length <= INN_LENGHTS.MAX);
    };

    this.setState((prevState) => {
      const updatedSegments = [...prevState.JsonData.Segments];

      if (field === FIELDS.PROVIDERNAME) {
        const isAlvist = value.value === ALVISTA_PROVIDERS.value;

        updatedSegments[index] = {
          ...updatedSegments[index],
          ProviderName: value.label,
          ProviderInn: isAlvist ? LABELS.INN_ALVIST : '',
        };
      } else if (field === FIELDS.PROVIDER_INN) {
        if (!isValidINN(value)) return null;

        updatedSegments[index] = {
          ...updatedSegments[index],
          ProviderInn: value,
        };
      } else {
        updatedSegments[index] = {
          ...updatedSegments[index],
          [field]: value,
        };
      }

      return {
        JsonData: {
          ...prevState.JsonData,
          Segments: updatedSegments,
        },
      };
    });
  };

  renderCompany = () => {
    const { companies } = this.props;
    const { CompanyId, validation } = this.state;

    return (
      <SelectCompany
        currentCompanyId={ CompanyId }
        companies={ companies }
        onSelect={ this.handleSelectCompany }
        validationCompany={ validation.CompanyId }
      />
    );
  };

  renderAnalytics = () => {
    const {
      analytics,
      tripAnalyticsValueIds,
      isTripPaidByPersonalFunds,
    } = this.props;
    const { UserAnalytics, validation } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <AnalyticsRows
        analytics={ analytics }
        serviceAnalyticsValueIds={ UserAnalytics }
        tripAnalyticsValueIds={ tripAnalyticsValueIds }
        onSelect={ this.handleSelectAnalytics }
        validation={ validation.analytics }
      />
    );
  };

  renderProject = () => {
    const { projects, isTripPaidByPersonalFunds } = this.props;
    const {
      ProjectId, validation, CompanyId, mandatoryProject,
    } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <SelectProject
        disabled={ !CompanyId }
        currentProjectId={ ProjectId }
        validationProject={ validation.ProjectId }
        mandatoryProject={ mandatoryProject }
        projects={ projects }
        onSelect={ this.handleSelectProject }
      />
    );
  };

  renderFinanceOperations = () => {
    const { Surcharges } = this.state;

    const financeFieldsHtml = Surcharges.Enabled
      ? renderFundsForm(Surcharges, this.handleChangeSurcharges, FUNDSFIELDSTYPES.SURCHARGES, SERVICETYPE.EVENT)
      : null;

    return (
      <div className={ `${styles.row} ${styles.panel}` }>
        <h4>{ LABELS.FINANCE_OPERATIONS }</h4>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <Checkbox
              field={ FUNDSFIELDS.SURCHARGES }
              label={ FUNDSLABELS.SURCHARGES_CUSTOM }
              value={ Surcharges.Enabled }
              onChange={ this.handleChangeFinancesEnabled }
            />
            { financeFieldsHtml }
          </div>
        </div>
      </div>
    );
  };

  renderVatButtons = () => {
    if (this.props.isEditing) return null;

    const { PriceDetails, JsonData } = this.state;
    const Segments = JsonData ? JsonData.Segments : [];

    const isDisabled = Segments && Segments.length > 0;

    return (
      <div className={ `${styles['col-1-4']} ${styles['vat-action']}` }>
        <Radio
          label={ LABELS_VAT.WITH }
          field={ FIELDS.HASVAT }
          checked={ PriceDetails.hasVat === VAT_VALUE.WITH }
          value={ VAT_VALUE.WITH }
          onChange={ (field, value) => this.handleChangeVat(field, value) }
          disabled={ isDisabled }
        />
        <Radio
          label={ LABELS_VAT.WITHOUT }
          field={ FIELDS.HASVAT }
          checked={ PriceDetails.hasVat === VAT_VALUE.WITHOUT }
          value={ VAT_VALUE.WITHOUT }
          onChange={ (field, value) => this.handleChangeVat(field, value) }
          disabled={ isDisabled }
        />
      </div>
    );
  };

  renderEditHistory = () => {
    const { Description, validation } = this.state;
    const { isEditing } = this.props;

    if (!isEditing) return null;

    return (
      <div className={ `${styles.row} ${styles.panel}` } ref={ (ref) => { this.refDescription = ref; } }>
        <h4>{ LABELS.NAME_DESCRIPTION }</h4>
        <div className={ styles.row } >
          <Input
            field={ FIELDS.DESCRIPTION }
            value={ Description }
            label={ LABELS.DESCRIPTION }
            onChange={ this.handleChangeDescription }
            onBlur={ () => this.validationHistory(FIELDS.DESCRIPTION) }
            valid={ validation.Description }
          />
        </div>
      </div>
    );
  };

  renderOptions = () => this.state.serviceTypes.map((type, id) => (
    <option key={ id } value={ type }>{ type }</option>
  ));

  renderDay = (items, currentDate) => {
    const todayDate = getMoment();
    const currentDateFormat = getDate(currentDate);

    if (isBeforeDateParameter(currentDate, todayDate)) {
      return <td { ...items } className={ `${items.className} ${styles['date-before']}` }>{ currentDateFormat }</td>;
    }

    return <td { ...items }>{ currentDateFormat }</td>;
  };

  renderUploadVoucher = () => {
    const { eventFile } = this.state;
    const { tripService: { resetEventFile } } = this.props;

    return eventFile.map((file, ind) => {
      const buttonAddVoucherHtml = file.file && ind === eventFile.length - 1 && !file.guid
        ? (
          <div className={ styles['col-1-4'] }>
            <FlatButton
              label={ LABELS.ADD_NEW_VOUCHER }
              onClick={ this.handleAddNewVoucher }
            />
          </div>
        )
        : null;

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <UploadVoucher
              uploadFile={ (item) => this.uploadFile(item, ind) }
              eventFile={ file }
              resetEventFile={ () => resetEventFile(ind) }
            />
          </div>
          { buttonAddVoucherHtml }
        </div>
      );
    });
  };

  renderINN = () => {
    const {
      Providers,
      validation,
      ProviderInn,
    } = this.state;

    const isAlvist = (Providers?.value ?? ALVISTA_PROVIDERS.value) === ALVISTA_PROVIDERS.value;

    const innValue = isAlvist ? LABELS.INN_ALVIST : ProviderInn || '';

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.PROVIDER_INN }
          value={ innValue }
          label={ TRIPSLABELS.INN }
          onChange={ (e, field, value) => this.handleChangeNumberInput(field, value) }
          onBlur={ (e) => this.validateProviderInn(e, FIELDS.PROVIDER_INN) }
          valid={ validation.ProviderInn }
          disabled={ isAlvist }
        />
      </div>
    );
  };

  renderSupplier = () => {
    const {
      PriceDetails,
      validation,
      Providers,
      JsonData: { Segments = [] },
    } = this.state;

    const handleInputChange = ({ target: { value } }, index, field) => {
      this.handleChangeProviderField(value, index, field);
    };

    const handlePriceChange = ({ target: { value } }, index, field) => {
      this.handleChangePriceInput(field, value, index);
    };

    const renderProviders = () => Segments.map((segment, index) => {
      const segmentErrors = validation?.Segments?.[index] || {};

      return (
        <div key={ index } className={ styles.row }>
          <div className={ styles['col-1-4'] }>
            <Input
              field={ `${FIELDS.ORDER_ID}_${index}` }
              value={ segment.OrderId || '' }
              label={ `${LABELS.ORDER_ID} #${index + 1}` }
              onChange={ (e) => handleInputChange(e, index, FIELDS.ORDER_ID) }
              onBlur={ () => this.validateSegments(FIELDS.ORDER_ID, index) }
              valid={ segment.validation?.OrderId }
            />
          </div>
          <div className={ styles['col-1-4'] }>
            <SelectWithInput
              label={ `${LABELS.PROVIDER_NAME} #${index + 1}` }
              items={ PROVIDER }
              currentItem={
                PROVIDER.find((item) => item.label === segment.ProviderName)
                || (typeof segment.ProviderName === 'string' ? { label: segment.ProviderName, value: segment.ProviderName } : Providers)
              }
              onChange={ (value) => { this.handleChangeProviderField(value, index, FIELDS.PROVIDERNAME); } }
              typeInput={ COMPONENTS.INPUT.TYPE.TEXT }
              onBlur={ () => this.validateSegments(FIELDS.PROVIDER_NAME, index) }
              valid={ segmentErrors.ProviderName && segment.ProviderName ? '' : segmentErrors.ProviderName }
            />
          </div>
          <div className={ styles['col-1-4'] }>
            <Input
              field={ `${FIELDS.PROVIDER_INN}_${index}` }
              value={ segment.ProviderInn || '' }
              label={ `${TRIPSLABELS.INN} #${index + 1}` }
              onChange={ ({ target: { value } }) => {
                if (segment.ProviderName !== ALVISTA_PROVIDERS.label) {
                  this.handleChangeProviderField(value, index, FIELDS.PROVIDER_INN);
                }
              } }
              onBlur={ (e) => this.validateSegmentsInn(e, FIELDS.SEGMENT_INN, index) }
              valid={ segmentErrors.ProviderInn && segment.ProviderInn ? '' : segmentErrors.ProviderInn }
              disabled={ segment.ProviderName === ALVISTA_PROVIDERS.label }
            />
          </div>
          <FlatButton
            label={ LABELS.DELETE_SUPPLIER }
            onClick={ () => this.handleRemoveProvider(index) }
            disabled={ Segments.length === 0 }
          />
          <div className={ `${styles.row} ${styles.panel}` }>
            <h4>{ LABELS.PRICE }</h4>
            <div className={ styles.row }>
              <div className={ styles['col-1-3'] }>
                <Input
                  field={ `${FIELDS.BASEPRICE}_${index}` }
                  value={ segment.Base || '' }
                  type={ COMPONENTS.INPUT.TYPE.NUMBER }
                  label={ `${LABELS.BASE} #${index + 1}` }
                  onChange={ (e) => handlePriceChange(e, index, FIELDS.BASEPRICE) }
                  onBlur={ () => this.validateSegments(FIELDS.SEGMENT_BASE, index) }
                  valid={ segmentErrors.Base && segment.Base ? '' : segmentErrors.Base }
                />
              </div>
              <div className={ styles['col-1-3'] }>
                <Input
                  field={ `${FIELDS.COMMISSION_EVENT}_${index}` }
                  value={ segment.Commission || '' }
                  label={ `${LABELS.COMMISSION} #${index + 1}` }
                  type={ COMPONENTS.INPUT.TYPE.NUMBER }
                  onChange={ (e) => handlePriceChange(e, index, FIELDS.COMMISSION_EVENT) }
                  onBlur={ () => this.validateSegments(FIELDS.SEGMENT_COMMISSION, index) }
                  valid={ segment.validation?.Commission }
                />
              </div>
              <div className={ `${styles['col-1-4']} ${styles['vat-action']}` }>
                <Radio
                  label={ LABELS_VAT.WITH }
                  field={ `${FIELDS.HASVAT}_${index}` }
                  checked={ PriceDetails.hasVat === VAT_VALUE.WITH }
                  value={ VAT_VALUE.WITH }
                  onChange={ this.handleChangeVat }
                  disabled={ index !== 0 }
                />
                <Radio
                  label={ LABELS_VAT.WITHOUT }
                  field={ `${FIELDS.HASVAT}_${index}` }
                  checked={ PriceDetails.hasVat === VAT_VALUE.WITHOUT }
                  value={ VAT_VALUE.WITHOUT }
                  onChange={ this.handleChangeVat }
                  disabled={ index !== 0 }
                />
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <>
        { Segments.length > 0 && renderProviders() }
        <div className={ styles.provider_button }>
          <FlatButton label={ LABELS.ADD_SUPPLIER } onClick={ this.handleAddProvider } />
        </div>
      </>
    );
  };

  calculateTotalPrice = () => {
    const { JsonData: { Segments }, PriceDetails } = this.state;

    if (Segments && Segments.length > 0) {
      const totals = Segments.reduce(
        (total, segment) => {
          const basePrice = parseFloat(segment.Base || 0);
          const commission = parseFloat(segment.Commission || 0);

          return {
            baseTotal: total.baseTotal + basePrice,
            CommissionTotal: total.CommissionTotal + commission,
          };
        },
        { baseTotal: 0, CommissionTotal: 0 },
      );

      return totals;
    } else {
      return {
        baseTotal: PriceDetails.Base || 0,
        CommissionTotal: PriceDetails.Commission || 0,
      };
    }
  };

  render() {
    const {
      PriceDetails,
      validation,
      isEdit,
      Providers,
      isLoading,
      JsonData: { Segments },
    } = this.state;

    const { isEditing } = this.props;

    const data = this.state.JsonData;

    const validCheckinDateHtml = validation.CheckinDate ? <span className='error-msg'>{ validation.CheckinDate }</span> : null;
    const validCheckoutDateHtml = validation.CheckoutDate ? <span className='error-msg'>{ validation.CheckoutDate }</span> : null;

    const financeOperationsHtml = isEditing ? this.renderFinanceOperations() : null;

    const labelButton = isEditing || isEdit ? LABELS.CONFIRM : LABELS.ADD_TRIP;

    const { baseTotal, CommissionTotal } = this.calculateTotalPrice();
    const canEditPrices = !Segments || Segments.length === 0;

    const checkDate = (value) => (isValidMomentObject(value) ? value : null);

    return (
      <div className={ styles.wrap }>
        { this.renderEditHistory() }
        <div className={ `${styles.row} ${styles.panel}` }>
          <h4>{ LABELS.VOUCHER_INFO }</h4>
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.NAME }
                value={ data.Name }
                label={ LABELS.NAME }
                onChange={ this.handleChangeInput }
                onBlur={ () => this.validationInput(FIELDS.NAME) }
                valid={ validation.Name }
              />
            </div>
            { this.renderCompany() }
            { this.renderProject() }
          </div>
          { this.renderAnalytics() }
          <div className={ `${styles.row}` }>
            <div className={ styles['col-1-4'] }>
              <label>{ LABELS.CHECKIN_DATE }</label>
              <DatePicker
                isHideCurrentDate
                type='date'
                value={ checkDate(data.CheckinDate) }
                onChange={ (value) => this.handleChangeDate(FIELDS.CHECKIN_DATE, value) }
                inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
                className={ `componentHook ${validation.CheckinDate ? 'no-valid' : ''}` }
              />
              { validCheckinDateHtml }
            </div>
            <div className={ styles['col-1-4'] }>
              <label>{ LABELS.CHECKOUT_DATE }</label>
              <DatePicker
                isHideCurrentDate
                type='date'
                value={ checkDate(data.CheckoutDate) }
                onChange={ (value) => this.handleChangeDate(FIELDS.CHECKOUT_DATE, value) }
                inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
                className={ `componentHook ${validation.CheckoutDate ? 'no-valid' : ''}` }
              />
              { validCheckoutDateHtml }
            </div>
          </div>
          <div className={ `${styles.row}` }>
            <div className={ styles['col-1-4'] }>
              <Input
                field={ FIELDS.ORDER_ID }
                value={ data.OrderId }
                label={ LABELS.ORDER_ID }
                onChange={ this.handleChangeInput }
              />
            </div>
            <div className={ styles['col-1-4'] }>
              <SelectWithInput
                label={ LABELS.PROVIDER_NAME }
                items={ PROVIDER }
                currentItem={ Providers || ALVISTA_PROVIDERS }
                onChange={ this.handleSelectProvider }
                typeInput={ COMPONENTS.INPUT.TYPE.TEXT }
                valid={ validation.ProviderName }
              />
            </div>
            { this.renderINN() }
            <div className={ `${styles.row} ${styles.panel}` }>
              <h4>{ LABELS.PRICE }</h4>
              <div className={ styles.row }>
                <div className={ styles['col-1-3'] }>
                  <Input
                    field={ FIELDS.BASEPRICE }
                    value={ canEditPrices ? PriceDetails.Base : baseTotal.toFixed(2) }
                    type={ COMPONENTS.INPUT.TYPE.NUMBER }
                    label={ LABELS.BASE }
                    onChange={ (_e, field, value) => this.handleChangePriceInput(field, value, null) }
                    onBlur={ () => this.validationPrice(FIELDS.BASEPRICE) }
                    valid={ validation.Base }
                    disabled={ !canEditPrices }
                  />
                </div>
                <div className={ styles['col-1-3'] }>
                  <Input
                    field={ FIELDS.COMMISSION_EVENT }
                    value={ canEditPrices ? PriceDetails.Commission : CommissionTotal.toFixed(2) }
                    label={ LABELS.COMMISSION }
                    type={ COMPONENTS.INPUT.TYPE.NUMBER }
                    onChange={ (_e, field, value) => this.handleChangePriceInput(field, value, null) }
                    onBlur={ () => this.validationPrice(FIELDS.COMMISSION_EVENT) }
                    valid={ validation.Commission }
                    disabled={ !canEditPrices }
                  />
                </div>
                { this.renderVatButtons() }
              </div>
            </div>
          </div>
        </div>
        { this.renderSupplier() }
        <div className={ `${styles.row}` }>
          { financeOperationsHtml }
        </div>
        { this.renderUploadVoucher() }
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ labelButton }
              disabled={ isLoading }
            />
          </div>
        </div>
      </div>
    );
  }
}
